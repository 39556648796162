<template>
    <div class="page_bg">
        
        <keep-alive >
            <router-view></router-view>
        </keep-alive>
        <xwTabbar v-if="$store.state.windowWidth <= 1000" />
    </div>
</template>

<script>

import xwTabbar from "@/components/xwTabbar.vue";
export default {
    name:'xwLayout',
    components:{
        xwTabbar
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="less" scoped>
.page_bg{
    background: #fff;
    min-height: var(--vh);
    @media (max-width:1000px) {
        width: 100vw;
        max-height: var(--vh);
        padding-bottom: 70px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>