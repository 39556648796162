import Vue from 'vue';
import xwDialog from './xwDialog.vue';
import i18n from '@/i18n/index'

const ModalConstructor = Vue.extend(xwDialog);

const instance = new ModalConstructor({i18n});

instance.$mount(document.createElement('div'));
document.body.appendChild(instance.$el);

const ModalPlugin = {
  install(Vue) {
    Vue.prototype.$xwDialog = {
      open({ title, content,btnTxt,hasClose,onClose, onConfirm }) {
        instance.$off('close'); // 确保不重复绑定
        if (onClose && typeof onClose === 'function') {
          instance.$on('close', onClose);
        }
        
        instance.$off('confirm'); // 确保不重复绑定
        if (onConfirm && typeof onConfirm === 'function') {
          instance.$on('confirm', onConfirm);
        }
        instance.open({ title, content,btnTxt,hasClose });
      },
      close() {
        instance.close();
      }
    };
  }
};

export default ModalPlugin;