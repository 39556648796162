import helper from '../common/helper.js';
import { getWsToken } from "@/api/user"

class socketClient {
    websocket = null;
    connectURL = '';
    // 连接状态
    isSocketOpen = false;
    // 心跳定时器
    heartbeatTimer = null;
    // 心跳发送频率（毫秒）
    heartbeatInterval = 9000;
    // 是否自动重连
    shouldReconnect = true;
    // 最大重连次数
    maxReconnectAttempts = 3;
    // 当前重连次数
    currentReconnectAttempts = 0;
    // 网络错误提示次数
    connectErrorCount = 0;
    // 重连定时器
    reconnectTimer = null;
    // 重连频率（毫秒）
    reconnectInterval = 5000;
    receiveMessageCallback = null;
    wsUrl = "";

    // 初始化Socket连接
    initSocket(receiveMessageCallback) {
        this.receiveMessageCallback = receiveMessageCallback;
        getWsToken().then(response => {
            if (response.data.code === 1) {
                const res = response.data.data;
                helper.set("socketUrl", res.WebSocket);
                helper.set("socketToken", res.token);
                helper.set("socketUid", res.uid);
                const url = res.WebSocket;
                if (url) {
                    this.initWebSocket(this.receiveMessageCallback, url);
                }
            }
        });
    }

    // 初始化WebSocket
    initWebSocket(receiveMessageCallback, wsUrl, onOpen) {
        this.onOpen = onOpen;
        if (!('WebSocket' in window)) {
            return null;
        }
        if (!helper.get("socketToken")) {
            return;
        }
        this.receiveMessageCallback = receiveMessageCallback;
        this.wsUrl = wsUrl || this.wsUrl;

        if (!this.wsUrl) {
            console.error('WebSocket URL错误：' + this.wsUrl);
            return;
        }

        const mainWs = new WebSocket(this.wsUrl);
        mainWs.onmessage = (msg) => {
            const info = helper.parseMsg(msg.data);
            if (info.order === 'server') {
                const msgUrl = (window.location.protocol === 'http:' ? 'ws' : 'wss') + '://' + info.host + '/ws' + info.port;
                helper.set('socketMsgUrl', msgUrl);
                this.createMessageSocket(this.receiveMessageCallback, msgUrl);
                mainWs.close();
            }
        };

        mainWs.onclose = () => {
            console.log('主连接断开');
        };

        mainWs.onopen = () => {
            if (mainWs.readyState === WebSocket.OPEN) {
                console.log('主连接成功');
                mainWs.send('type=askserver');
            }
        };

        mainWs.onerror = (error) => {
            console.error('主连接错误：', error);
        };
    }

    // 创建消息WebSocket
    createMessageSocket(receiveMessageCallback, url) {
        this.websocket = new WebSocket(url);

        this.websocket.onmessage = (e) => {
            if (receiveMessageCallback) {
                receiveMessageCallback(e);
            }
        };

        this.websocket.onerror = (e) => {
            console.error('WebSocket错误：', e);
        };

        this.websocket.onclose = (e) => {
            clearInterval(this.heartbeatTimer);
            this.isSocketOpen = false;
            console.log('连接关闭，尝试重连', e);
            if (this.shouldReconnect) {
                this.reconnectTimer = setTimeout(() => {
                    if (this.currentReconnectAttempts >= this.maxReconnectAttempts) {
                        clearTimeout(this.reconnectTimer);
                        this.shouldReconnect = false;
                        return;
                    }
                    this.currentReconnectAttempts++;
                    this.reconnect();
                }, this.reconnectInterval);
            }
        };

        this.websocket.onopen = (e) => {
            this.isSocketOpen = true;
            this.shouldReconnect = true;
            this.currentReconnectAttempts = 0;
            this.send({
                type: 'add',
                uid: helper.get("socketUid"),
                token: helper.get("socketToken")
            });
            this.startHeartbeat();
            if (this.onOpen) this.onOpen();
            console.log('连接成功', e);
        };
    }

    // 发送消息
    send(data, callback = null) {
        if (!this.websocket) return;
        if (this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(helper.getQueryParams(data));
            if (callback) callback();
        } else {
            clearInterval(this.heartbeatTimer);
            if (this.connectErrorCount < 1) {
                // 提示未开启连接
            }
            this.connectErrorCount++;
        }
    }

    // 开启心跳
    startHeartbeat() {
        if (this.heartbeatTimer) {
            clearInterval(this.heartbeatTimer);
        }
        this.heartbeatTimer = setInterval(() => {
            this.send({ type: 'online' });
        }, this.heartbeatInterval);
    }

    // 关闭连接
    close() {
        clearInterval(this.heartbeatTimer);
        this.shouldReconnect = false;
        if (this.websocket) {
            this.websocket.close();
        }
    }

    // 重新连接
    reconnect() {
        if (this.websocket && !this.shouldReconnect) {
            this.close();
        }
        this.initSocket(this.receiveMessageCallback);
    }
}

export { socketClient };
