import {system_info,system_info_new, get_menu_list} from '@/api/index.js'
import helper from '@/common/helper.js';
export default async ({i18n})=>{
  const [res, resNew, resMenus] = await Promise.all([system_info(),system_info_new(), get_menu_list()])
  if (res.data.code == 1) {
      const messages = helper.deepMerge(res.data.lang, resNew.data.lang)
      for (let lang in messages) {
          i18n.mergeLocaleMessage(lang, messages[lang]);
      }
      if (res.data.locale && !helper.getLang()) {
          i18n.locale = res.data.locale;
          helper.setLang(res.data.locale);
          helper.set('i18n_icon', res.data.locale_flag);
      }
      helper.set('alllang', res.data.alllang);
      helper.set('idc', res.data.idc);
      helper.set('i18n_messages', messages);
      helper.set('rule', {...res.data.data,...resNew.data.site});
      helper.setHeadInfo(resNew.data.site);
  }

  if (resMenus.data.code == 1) {
    const menus = resMenus.data.data;
    // console.log(menus, 'menus')
    for (const key in menus) {
        if (Object.hasOwnProperty.call(menus, key)) {
            const element = menus[key];
            helper.set(key, element);
        }
    }
  }
}