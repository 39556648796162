<template>
    <div class="xw_tabbar">
        <div class="xw_tabbar_wrap">

        
        <div class="tabbar_item" :class="{active:$route.path==item.href}" v-tooltip="{ message: $t('請先登入賬號') }" @click="changePage(item.href,index)"
            v-for="(item, index) in menuArr" :key="index">
            <div class="tabbar_icon" :class="{frist_cd:isHomePage&&index==0}" v-if="index === 0"
                :style="`background-image:url(${isHomePage ? item.icon_url : homeItem.icon_url});`">
            </div>
            <div class="tabbar_icon" v-else :style="`background-image:url(${item.icon_url});`">
            </div>
            <span v-if="index === 0">{{ isHomePage ? item.name : homeItem.name }}</span>
            <span v-else>{{ item.name }}</span>
        </div>

        <div class="footer_DW_open" v-if="walletPop" @click.self="walletPop = false">
            <div class="footer_DW_open_wallet">
                <button type="button" @click="changePage('/memberCenter/TRANSFER')" class="wallet_btn bg_blue">
                    {{ $t('平台轉帳') }}
                </button>
                <button type="button" @click="changePage('/memberCenter/DEPOSIT')" class="wallet_btn">
                    <img src="@/assets/images/xw/icon_footer_deposit2.svg" alt="">
                    {{ $t('存款') }}
                </button>
                <button type="button" @click="changePage('/memberCenter/WITHDRAWAL')" class="wallet_btn btn_withdrawal">
                    <img src="@/assets/images/xw/icon_footer_withdrawal2.svg" alt="">
                    {{ $t('提款') }}
                </button>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuArr: [],
            walletPop: false,

            homeItem: {
                name: '首頁',
                href: '/',
                icon_url: require('@/assets/images/xw/icon_footer_home_new.png')
            }
        }
    },
    computed: {
        isHomePage() {
            return this.$route.path === '/';
        }
    },
    mounted() {
        this.menuArr = this.$helper.get('xw_mobile_navbar')
    },
    methods: {
        changePage(url,index) {
            if (this.$store.state.isLoginStatu) {
                if (url == '/wallet') {
                    this.walletPop = !this.walletPop
                } else {
                    if(index===0&&!this.isHomePage){
                        this.walletPop = false
                        this.$router.push({ path: '/' })
                    }else{
                        this.walletPop = false
                        this.$router.push({ path: url })
                    }
                    
                }
            }
        },

    }
}

</script>

<style lang="less" scoped>


.xw_tabbar {
    position: fixed;
    width: 100vw;
    height: 60px;
    left: 0;
    bottom: 0;
    z-index: 999;
    background-color: #fff;
}

.xw_tabbar_wrap{
    width: 100vw;
    height: 46px;
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e1e1e2;
}

.tabbar_item {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-top: 5px;
    z-index: 999;

    .tabbar_icon {
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% auto;
        margin: 0 auto 1px;
    }

    span{
        transform: translateY(-2px);
    }

    // &:first-child {
    //     .tabbar_icon {}
    // }

    &:nth-child(3) {
        // background: #fff;
        padding: 40px 4px 0;

        .tabbar_icon {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 18px;
            z-index: 3;
            width: 64px;
            height: 64px;
            background-position-y: top;
            margin: auto;
        }

    }
}

.tabbar_item.active{
    color: #4aab98 !important;
}

.footer_DW_open {
    position: fixed;
    width: 100vw;
    height: calc(var(--vh) - 49px);
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    right: 0;
    bottom: 60px;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 99;
}

.footer_DW_open_wallet {
    position: absolute;
    width: 100%;
    bottom: -1px;
    pointer-events: initial;
    height: 83px;
    background-color: #fff;
    display: flex;
    transition: all .2s;
    border-bottom: 1px solid #e1e1e2;
    padding: 0 16px;
    z-index: 9999;
    gap: 12px;
}

.wallet_btn {
    flex: 1;
    border-radius: 10px;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    box-sizing: border-box;
    text-decoration: none;
    line-height: 18px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    background-color: #0a9d4c;
    flex: 2;
    padding-top: 0 !important;
    gap: 4vw;

    img {
        width: auto;
        height: 32px;
    }
}

.bg_blue {
    background-color: #3c92c5;
}


.btn_withdrawal {
    background-color: #f79319
}
</style>