<template>
  <div v-if="visible" class="modal_overlay" @click="handleBackgroundClick">
    <div class="modal_container" @click.stop>
      <div class="modal_header">
        <span class="modal_title">{{ title }}</span>
      </div>
      <div class="modal_body" v-html="content"></div>
      <div class="modal_footer">
        <button v-if="closeShow" class="modal_btn modal_cancel" @click="close">
          {{$t('離開')}}
        </button>
        <button class="modal_btn modal_ok" @click="confirm">
          {{confirmBtnTxt}}
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  
  export default {
    data() {
      return {
        visible: false,
        title: '',
        content: '',
        confirmBtnTxt:this.$t('確認'),
        closeShow:true
      };
    },
    methods: {
      open({ title, content,hasClose,btnTxt }) {
        this.title = title;
        this.content = content;
        this.confirmBtnTxt = btnTxt||this.$t('確認')
        this.closeShow = hasClose
        this.visible = true;
      },
      close() {
        this.$emit('close');
        this.visible = false;
      },
      confirm() {
        this.$emit('confirm');
        this.visible = false;
      },
      handleBackgroundClick() {
        this.close();
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  }
  .modal_container {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 288px;
  }
  .modal_header {
    background-color: unset;
    border-bottom: unset;
    padding: 0;
    text-align: center;
    line-height: 55px;
    font-weight: 600;
    font-size: 16px;
    height: auto;
    color: #000;
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 !important;
  }
  .modal_title {
    margin: 0;
  }
  .modal_close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  .modal_body {
    padding: 0;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    margin: 3px 4%;
    padding-bottom: 15px;
    color: #333;
    position: relative;
    word-break: break-word !important;
  }
  .modal_footer {
    display: flex;
    border-top: 1px solid #eee;
    padding: unset;
  }
  .modal_btn {
    flex: 1;
    width: 50%;
    border-color: unset;
    background-color: unset;
    height: 40px;
    line-height: normal !important;
    outline: none;
    &:hover{
      background-color: #e5e5e5;
    }
  }
  .modal_ok{
    color: #3f9eff;
    width: 100%;
  }
  .modal_cancel{
    color: #a1a1a1 ;
    border-right: 1px solid #eee;
  }
  </style>