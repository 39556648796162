// v-auth-tooltip.js

let currentTooltip = null; // 全局变量跟踪当前显示的 Tooltip
let hideTimeout = null; // 全局变量跟踪当前 Tooltip 的隐藏超时

export default {
  bind(el, binding, vnode) {
    const message = binding.value.message || '请登录';
    const store = vnode.context.$store;

    const showTooltip = (event) => {
      if (store.state.isLoginStatu) {
        // 用户已登录，不显示 Tooltip，允许正常跳转
        return;
      }

      event.preventDefault();

      if(store.state.windowWidth <= 1000){
            return store.state.loginVisible = true;
      }
      
      // 如果当前有显示的 Tooltip，立即隐藏
      if (currentTooltip) {
        currentTooltip.style.display = 'none';
        if (hideTimeout) {
          clearTimeout(hideTimeout);
        }
      }

      // 创建并显示新的 Tooltip
      currentTooltip = document.createElement('div');
      currentTooltip.className = 'tooltip';
      currentTooltip.textContent = message;
      document.body.appendChild(currentTooltip);

      const rect = el.getBoundingClientRect();
      const tooltipHeight = 40; // 预估 Tooltip 的高度
      const buffer = 3; // 距离元素的间隔
      let top, left;

      if (rect.bottom + tooltipHeight + buffer > window.innerHeight) {
        // 如果元素接近窗口底部，则在元素上方显示 Tooltip
        top = rect.top + window.scrollY - tooltipHeight - buffer;
      } else {
        // 否则在元素下方显示 Tooltip
        top = rect.bottom + window.scrollY + buffer;
      }

      left = rect.left + window.scrollX + 10;

      currentTooltip.style.position = 'absolute';
      currentTooltip.style.top = `${top}px`;
      currentTooltip.style.left = `${left}px`;
      currentTooltip.style.backgroundColor = '#3595cc';
      currentTooltip.style.boxShadow = '2px 2px 5px rgba(0,0,0,0.3)'
      currentTooltip.style.color = '#fff';
      currentTooltip.style.padding = '5px 10px';
      currentTooltip.style.borderRadius = '5px';
      currentTooltip.style.whiteSpace = 'nowrap';
      currentTooltip.style.zIndex = '1000';
      currentTooltip.style.display = 'block';

      hideTimeout = setTimeout(() => {
        currentTooltip.style.display = 'none';
        currentTooltip = null;
      }, 3000);
    };

    el.addEventListener('click', showTooltip);

    el._authTooltip = { showTooltip }; // Store the event listeners for later removal
  },
  unbind(el) {
    if (el._authTooltip) {
      el.removeEventListener('click', el._authTooltip.showTooltip);
      delete el._authTooltip;
    }
  }
};
