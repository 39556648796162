<template>
  <!-- :visible.sync="isShow" -->
  <el-dialog :modal-append-to-body="false" :visible.sync="isShow"
    v-if="$route.query.modal != 'LoginRegistration' && !$store.state.isOpenNewPage && $route.path == '/'"
    :show-close="true" :close-on-click-modal="true" custom-class="custom-dialog my-dialog">

    <el-dialog title="公告詳情" :visible.sync="detailShow" custom-class="custom-dialog my-dialog" :show-close="true"
      append-to-body>

      <div class="pop_body detail_container">
        <div v-html="noticeDetail"></div>
      </div>
    </el-dialog>

    <div class="custom-dialog-head" slot="title">
      <div class="header__title">
        <h1>{{ $t('公告欄') }}</h1>
      </div>
    </div>

    <div class="pop_body">
      <div class="tab_list">
        <div @click="changeTabType(index)" v-for="(item, index) in typeTabList" :key="index" class="tab_item"
          :class="{ tab_ac: typeActive === index }">
          <img :src="item.icon" class="tab_icon" :class="{ gray_filter: typeActive != index }" alt="">
          {{ item.name }}
        </div>

      </div>
      <div class="notice_wrap" v-loading="noticeLoading">
        <div v-if="list.length == 0" class="nomore"> {{ $t('暂无更多') }}</div>
        <div v-else>
          <div @click="openDetail(index)" v-for="(item, index) in list" :key="index" class="notice_item">
            <div class="notice_type_icon_wrap">
              <div class="notice_type_icon">
                <img :src="showIcon(item.type_id)" class="n_type_icon" alt="">
              </div>
            </div>
            <div class="notice_info">
              <p class="notice_item_title"> {{ item.title }}</p>
              <span>{{ item.add_time }}</span>
            </div>
          </div>
        </div>


      </div>

      <div class="bottom-btn-wapper">
        <div class="tip-txt" @click="hide(true)">{{ $t('今天不要提醒我') }}</div>
      </div>
    </div>

    <!-- <swiper class="turntable-thumbs" :options="turntableThumbs" ref="swiperThumbs">

      <swiper-slide v-for="(item, index) in list" :key="index">
        <div class="dialog-wapper">
          <div class="html" v-html="list[index].content"></div>
          <div class="bottom-btn-wapper">
            <el-button class="button_jade" @click="toPage(item.jump_url)">{{ $t("瞭解更多") }}</el-button>
            <div class="tip-txt" @click="hide(true)">{{ $t('今天不要提醒我') }}</div>
          </div>
          <div class="slider-wapper">
            <div :class="['slider-item', cur == index ? 'item-ac' : '']" v-for="item, cur in list" :key="cur"></div>
          </div>
        </div>
      </swiper-slide>
    </swiper> -->
    <!-- <div v-if="list.length !=0 " class="splide__arrow--next-notice">
      <div>
        <img :src="require('@/assets/images/kno_next.png')">
      </div>
     
    </div>
    <div v-if="list.length !=0 " class="splide__arrow--prev-notice">
      <img :src="require('@/assets/images/kno_next.png')">
    </div> -->



  </el-dialog>
</template>

<script>
import { notice_type_api, notice_list_api } from "@/api";
export default {
  data() {
    return {
      isShow: false,
      list: [],
      cur: 0,
      checked: false,
      turntableThumbs: {
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".splide__arrow--next-notice",
          prevEl: ".splide__arrow--prev-notice"
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      typeTabList: [
        {
          icon: require('@/assets/images/xw/icon_all_ntc_ac.png'),
          name: '全部公告'
        }
      ],
      typeActive: 0,
      noticeLoading: true,
      detailShow: false,
      noticeDetail:''
    };
  },
  mounted() {
    this.show();
  },
  watch: {
    "$store.state.isLoginStatu": function (v) {
      if (v) this.show()
    },
  },
  methods: {
    openDetail(index){
      console.log(index,'index');
      this.noticeDetail = this.list[index].content
      this.detailShow = true
    },
    showIcon(id) {
      if (id <= 1) {
        return this.typeTabList.find(item => item.id == 1).icon
      } else {
        return this.typeTabList.find(item => item.id == id).icon
      }
    },
    toPage(url) {
      this.hide()
      if (url.startsWith('http')) {
        location.href = url
      } else {
        this.$router.push(url)
      }
    },
    hide(isDay = false) {
      const key = "noticeHideTime";
      if (isDay) {
        const date = new Date();
        const time = `${date.getFullYear()}/${date.getMonth() +
          1}/${date.getDate()}`;
        localStorage.setItem(key, time);
      } else {
        localStorage.setItem(key, null);
      }
      this.isShow = false;
    },
    async show() {

      const key = "noticeHideTime";
      const date = new Date();
      const time = `${date.getFullYear()}/${date.getMonth() +
        1}/${date.getDate()}`;
      // 判断时间相等不显示，不相等进行显示
      if (time == localStorage.getItem(key)) return;
      const oldList = JSON.stringify(this.list)
      await this.getTabType()
      await this.getData();
      // 监听数据有变化就显示

      if (JSON.stringify(this.list) != oldList && this.list.length) {
        this.isShow = true;
      }
    },
    async getTabType() {
      const response = await notice_type_api()
      if (response.data.code == 1) {
        if (response.data.data.length != 0) {
          this.typeTabList.push(...response.data.data)
        }
      }
    },
    async getData() {
      this.noticeLoading = true
      let params = {
        page: 1,
        limit: 100,
        type: 2,
        show_loca: 1,
      }
      if (this.typeActive != 0) {
        params['type_id'] = this.typeTabList[this.typeActive].id
      }
      const res = await notice_list_api(params);
      if (res.data.code == 1) {
        this.list = res.data.data;

      }
      this.noticeLoading = false
    },
    changeTabType(key) {
      if (this.typeActive === key) return
      this.typeActive = key
      this.getData()
    },
    next() {
      if (this.list.length - 1 == this.cur) return;
      this.cur++;
    },
    pre() {
      if (this.list.length == 0) return;
      this.cur--;
    },
  },
};
</script>

<style lang="less" scoped>
.slider-wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4px;
  margin-top: 10px;
}

.slider-item {
  height: 100%;
  width: 10px;
  background-color: #1A283A;
  border-radius: var(--vh);
  margin-right: 6px;
}

.item-ac {
  width: 20px;
  background-color: #253852;

}

.slider-item:last-child {
  margin-right: 0;
}

.dialog-wapper {
  color: #000;
  background-color: #fff;
  /* border-radius: 20px; */
  overflow: hidden;
  padding: 20px;
}

.title-txt {
  color: #fff;
  font-size: 14px;
}
::v-deep .el-dialog__title{
  color: #fff;
}
::v-deep .el-checkbox__inner {
  background-color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0075ff !important;
  border-color: #0075ff !important;
}

::v-deep .el-dialog__header {
  /* display: none; */
  background: linear-gradient(0deg, #439BFB, #7BC7E0) !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  overflow: hidden;

}

::v-deep .el-dialog__body {
  background: transparent;
  padding: 0;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  overflow: hidden;
}

::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
  overflow: visible;
}

@media (max-width: 768px) {
  ::v-deep .el-dialog {
    /* padding: 20px; */
  }
}

.title-wapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-wapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.el-icon-arrow-right {
  margin-left: 6px;
}

.el-icon-arrow-left {
  margin-right: 6px;
}

.bottom-btn-wapper {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
}

.hide {
  visibility: hidden;
}

.button_jade {
  width: 100%;
  max-width: 366px;
  font-weight: bold;
  border-radius: var(--vh);
}

@media (max-width:540px) {
  .button_red {
    max-width: 90%;
  }
}

.close {
  margin-left: auto;
  display: block;
  font-size: 28px;
  text-align: right;
  transform: translateX(30px);
  color: #fff;
}

.close i {
  cursor: pointer;
}

@media (max-width: 768px) {
  .close {
    font-size: 20px;
    transform: translateX(20px);
  }
}

.tip-txt {
  color: #93acd3;
  font-size: 12px;
  cursor: pointer;
}

.html {
  height: 30vh;
  overflow: auto;
}

@media (max-width:768px) {
  .html {
    height: 30vh;
    overflow: auto;
  }
}

.splide__arrow--next-notice,
.splide__arrow--prev-notice {
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  z-index: 9999;
  cursor: pointer;
  --bj: 10px;
  --size: 40px;
}

@media (max-width:768px) {

  .splide__arrow--next-notice,
  .splide__arrow--prev-notice {
    --bj: 25px;
    --size: 30px;
  }
}

.splide__arrow--next-notice img,
.splide__arrow--prev-notice img {
  width: 100%;
  height: 100%;
}

.splide__arrow--prev-notice,
.splide__arrow--next-notice {
  opacity: .8;
}

.splide__arrow--next-notice {
  right: var(--bj);
}


.splide__arrow--prev-notice {
  left: var(--bj);
  transform: translateY(-60%) rotate(180deg);
}


.swiper-button-disabled {
  opacity: 0;
}

.pop_body {
  background-color: #fff;
}

.tab_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 12px 0;
  border-bottom: 1px solid #DBDBDB;
}

.tab_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  color: #8F8F8F;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;
}

.tab_item.tab_ac {
  color: #161F2C
}

.tab_item.tab_ac::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -1px;
  width: calc(100% + 8px);
  height: 3px;
  transform: translateX(-50%);
  background: #54A8F3;
  border-radius: 3px;
}

.tab_icon {
  width: 22px;
  height: 22px;
}

.gray_filter {
  filter: grayscale(100%);
}

.notice_wrap {
  height: 300px;
  overflow-y: auto;
}

.notice_item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  border-bottom: 1px solid #DBDBDB;
  cursor: pointer;
}

.notice_item:hover {
  background-color: #e0e8f4;
}

.notice_type_icon_wrap {
  width: 52px;
  height: 52px;
  padding: 1px;
  border: 1px solid #BFBFBF;
  border-radius: 50%;
}

.notice_type_icon {
  width: 100%;
  height: 100%;
  background: #BFBFBF;
  border-radius: 50%;
  padding: 12px;
  display: flex;
}

.notice_info {
  flex: 1;
  width: 70%;
}

.n_type_icon {
  width: 100%;
  max-height: 100%;
  height: auto;
  filter: brightness(0) invert(1);
}

.notice_item_title {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomore {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_container{
  padding: 12px 15px;
  height: 400px;
  overflow-y: auto;
}
</style>
